// @flow
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { Button, Chip } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import {
  ViajeTrasladoEstado,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment from 'moment';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationManagmentModel } from '../../gestion/models/TransferReservationManagment';
import {
  copyReservationPublicLinkToClipboard,
  copyReservationPublicNewLinkToClipboard
} from '../../viajeTraslado/services/copyReservationPublicLinkToClipboard';
import { TransferReservationOpenedModel } from '../models/TransferReservationOpened';
import { tw } from 'utils/tailwindUtils';

interface Props {
  tableQuery: UseTableQueryResult<TransferReservationOpenedModel[], unknown>;
  onLocationsDetails: (reservation: TransferReservationOpenedModel) => void;
  onReservationCopy: (reservation: TransferReservationManagmentModel) => void;
  onForceEnd: (reservation: TransferReservationOpenedModel) => void;
  setOpenToPending: (id: number) => void;
  onReAssignDriver: (reservation: TransferReservationOpenedModel) => void;
  onShowDriverDetails: (chofer: ChoferPartialModel) => void;
  onEditReservation: (travel: TransferReservationOpenedModel) => void;
  onCancelReservation: (reservation: TransferReservationOpenedModel) => void;
  onShowTravelDetails: (id: number) => React.ReactNode;
  onDelayNotify: (travel: TransferReservationOpenedModel) => void;
}

export function ViajeTrasladoOpenedTable({
  tableQuery,
  onReservationCopy,
  onLocationsDetails,
  onReAssignDriver,
  onShowDriverDetails,
  onEditReservation,
  setOpenToPending,
  onCancelReservation,
  onShowTravelDetails,
  onForceEnd,
  onDelayNotify
}: Props) {
  const snackbar = useSnackbar();
  const [reservationButtonElement, setReservationButtonElement] =
    React.useState<(EventTarget & HTMLElement) | null>(null);
  const eventIndex = React.useRef<number | null>(null);

  const data = (tableQuery.data ?? []).map((reservation) => ({
    ...reservation,
    schedule: {
      arrival: reservation.reservationDatetime,
      exit: reservation.departureDatetime,
      end: reservation.arrivalDateTime
    },
    passengers: reservation.locations
      .map((location) => location.passengers.map((passenger) => passenger.name))
      .flat()
  }));

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'schedule',
      label: 'Horarios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div
              className="grid w-32 grid-cols-2 items-center"
              style={{ columnGap: '1.6vw' }}
            >
              <span
                className={
                  'title col-span-1 ' +
                  (data[index].schedule.exit.isBefore(moment())
                    ? 'text-red-500'
                    : '')
                }
                title="Salida de chofer"
              >
                {data[index].schedule.exit.format('HH:mm')}
              </span>
              <span
                className={
                  'col-span-1 text-xs text-gray-400 ' +
                  (data[index].schedule.exit.isBefore(moment())
                    ? 'text-red-500'
                    : '')
                }
                title="Salida de chofer"
              >
                {data[index].schedule.exit.format('DD/MM')}
              </span>
              <span
                className="title col-span-1 font-bold"
                style={{ fontSize: 16 }}
                title="Llegada a origen"
              >
                {data[index].schedule.arrival.format('HH:mm')}
              </span>
              <span
                className="col-span-1 text-xs font-bold text-gray-700"
                style={{ fontSize: 13 }}
                title="Llegada a origen"
              >
                {data[index].schedule.arrival.format('DD/MM')}
              </span>
              <span
                className="title col-span-2 text-gray-400"
                title="Llegada a destino"
              >
                {Math.abs(
                  data[index].schedule.end.diff(
                    data[index].schedule.arrival,
                    'minutes'
                  )
                )}{' '}
                min.
              </span>
            </div>
          );
        }
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          const current = data[index] as any;
          return (
            <div className="w-44 flex gap-2 flex-col">
              <p>{current.client.name}</p>
              <div
                className={tw('hidden flex-col gap-1', {
                  flex: current.lltpais ? true : false
                })}
              >
                <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
                  LLT-País
                </div>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Proveedor
                  <span>${Number(current?.lltpais?.provider_price ?? 0)}</span>
                </p>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Cliente
                  <span>${Number(current?.lltpais?.customer_price ?? 0)}</span>
                </p>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: 'Obs. Coordinación'
    },

    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex items-center space-x-2">
            <p>
              {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
                ? null
                : data[index].serviceType}
            </p>{' '}
            {data[index].hasPriority && (
              <FontAwesomeIcon icon={faStar} color="orange" />
            )}
          </div>
        )
      }
    },
    {
      name: 'platformId',
      label: 'Plataforma'
    },
    {
      name: 'id',
      label: 'ID'
    },

    {
      name: 'assigned_driver',
      label: 'Chofer',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col items-center">
            <p
              className="cursor-pointer hover:underline"
              onClick={() => onShowDriverDetails(data[index].assigned_driver!)}
            >
              {data[index].assigned_driver?.codigo}
            </p>
            <p className="text-center text-xs">
              {data[index].assigned_driver?.nombreCompleto}
            </p>
          </div>
        )
      }
    },
    {
      name: 'passengers',
      label: 'Pasajeros',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex flex-col">
            {data[index].passengers.map((el) => (
              <span>{el}</span>
            ))}
          </div>
        )
      }
    },
    {
      name: 'contretemps',
      label: 'Contratiempos'
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        customBodyRenderLite: (index) => {
          let color = 'green';
          if (
            (data[index].state === ViajeTrasladoEstado.ASIGNADA &&
              Math.abs(data[index].state_datetime.diff(moment(), 'minutes')) >
                3) ||
            (data[index].state === ViajeTrasladoEstado.ACEPTADA &&
              Math.abs(
                data[index].reservationDatetime.diff(moment(), 'minutes')
              ) <= 20) ||
            (data[index].state === ViajeTrasladoEstado.EN_VIAJE_A_ORIGEN &&
              data[index].reservationDatetime.isBefore(moment()))
          ) {
            color = 'red';
          }
          return (
            <Chip variant="light" className="whitespace-nowrap" color={color}>
              {data[index].state}
            </Chip>
          );
        }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div>
            <Button
              onClick={() => onReAssignDriver(data[index])}
              variant="light"
              color="green"
              className="mb-2 w-full"
            >
              <span className="whitespace-nowrap">Re-asignar</span>
            </Button>

            <Button
              onClick={(ev) => {
                eventIndex.current = index;
                setReservationButtonElement(ev.currentTarget);
              }}
              variant="light"
              className="w-full"
              color="red"
            >
              Reserva
            </Button>
            <Menu
              anchorEl={reservationButtonElement}
              open={
                eventIndex.current === index &&
                Boolean(reservationButtonElement)
              }
              onClose={() => setReservationButtonElement(null)}
            >
              {onShowTravelDetails(data[index].id)}

              <MenuItem onClick={() => onEditReservation(data[index])}>
                Modificar
              </MenuItem>
              <MenuItem onClick={() => onCancelReservation(data[index])}>
                Cancelar
              </MenuItem>
              <MenuItem onClick={() => onReservationCopy(data[index])}>
                Copiar
              </MenuItem>
              <MenuItem onClick={() => onForceEnd(data[index])}>
                Finalizar
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await copyReservationPublicLinkToClipboard(data[index].id);
                  setReservationButtonElement(null);
                  snackbar.enqueueSnackbar('Link copiado', {
                    variant: 'info'
                  });
                }}
              >
                Copiar Link
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await copyReservationPublicNewLinkToClipboard(data[index].id);
                  setReservationButtonElement(null);
                  snackbar.enqueueSnackbar(
                    'Nuevo link de seguimiento copiado',
                    {
                      variant: 'info'
                    }
                  );
                }}
              >
                Copiar nuevo link de seguimiento
              </MenuItem>
              <MenuItem onClick={() => setOpenToPending(data[index].id)}>
                Volver a Pendiente
              </MenuItem>
              <MenuItem
                onClick={() => {
                  eventIndex.current = null;
                  setReservationButtonElement(null);
                  onDelayNotify(data[index]);
                }}
              >
                Notificar Demora
              </MenuItem>
            </Menu>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          print: false,
          download: false,
          ...tableQuery.serverSideOptions
        }}
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        columns={columns}
      />
    </div>
  );
}
